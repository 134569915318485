import React from "react"; import { useHistory } from "react-router-dom"; import AuthService from "../services/auth-service"; import socketIOClient from 
"socket.io-client"; import queryString from "query-string";

const ENDPOINT = "https://websocket.reckon.ai";

export default function Wrapper(props) {
  const history = useHistory();


  setInterval(() => history.push(history.location.pathname), 300000);
  
  React.useEffect(() => {
    let socket;
    async function main() {
      console.log(socket);
      if (socket) socket.disconnect();
      const encryptData = AuthService.encryptData;

      let resEncrypt = await encryptData({
        dns: "websocket.reckon.ai",
        data: { a: "a" },
      });
      socket = socketIOClient(
        ENDPOINT + `?authtoken=${resEncrypt.data.result}`
      );

      socket.on("connect", async function () {
        socket.emit("register", "0011");
        socket.on("welcome", (data) => {
          console.log(data);
        });
        socket.on("event", (data) => {
          if (data) {
            switch (data.event) {
              case "start":
                history.push("/");
                break;
              case "validation":
                history.push("/wait");
                break;
              case "open":
                history.push("/doorOpen");
                break;
              case "close":
                history.push("/processing/payment");
                break;
              case "basket":
                history.push("/purchase/value?"+queryString.stringify(data.data));
                break;
              case "machineError":
                history.push("/problem/tech");
                break;
              case "declinedCard":
                history.push("/problem/card");
                break;
              case "maintenance":
                history.push("/problem/maintenance");
                break;
              default:
                // code block
                console.log(data.event);
            }
          }
        });
      });
    }
    main();
    return history.listen((location) => {
      main();
    });
  }, [history]);

  return <>{props.children}</>;
}
